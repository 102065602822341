<template>
  <div class="customer-data">
    <section class="bgimage d-flex justify-content-center align-items-center text-center">
      <b-container>
          <b-row>
              <b-col class="pt-5">
                    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
                        <b-row>
                          <b-col>
                            <b-form-group
                                id="input-group-1"
                                label="Email adres:"
                                label-for="input-1"
                                description="Wij delen uw email met niemand anders."
                            >
                                <b-form-input
                                  id="input-1"
                                  v-model="form.email"
                                  type="email"
                                  required
                                  placeholder="Enter email"
                                ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group id="input-group-2" label="Your Name:" label-for="input-2">
                                <b-form-input
                                id="input-2"
                                v-model="form.name"
                                required
                                placeholder="Enter name"
                                ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group id="input-group-2" label="Straatnaam:" label-for="input-2">
                                <b-form-input
                                id="input-3"
                                v-model="form.street"
                                required
                                placeholder="Enter street"
                                ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col>
                            <b-form-group id="input-group-2" label="Postcode:" label-for="input-2">
                                <b-form-input
                                id="input-4"
                                v-model="form.postal"
                                required
                                placeholder="Enter postal code"
                                ></b-form-input>
                            </b-form-group>
                          </b-col>
                          <b-col>
                            <b-form-group id="input-group-2" label="Plaats:" label-for="input-2">
                                <b-form-input
                                id="input-4"
                                v-model="form.postal"
                                required
                                placeholder="Enter city"
                                ></b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>

                            <!-- <b-form-group id="input-group-3" label="Food:" label-for="input-3">
                                <b-form-select
                                id="input-3"
                                v-model="form.food"
                                :options="foods"
                                required
                                ></b-form-select>
                            </b-form-group> -->

                        <b-row>
                          <b-col>
                            <b-form-group id="input-group-4">
                                <b-form-checkbox-group v-model="form.checked" id="checkboxes-4">
                                <b-form-checkbox value="me">Ik ga akkoord met alle voorwaarden.</b-form-checkbox>
                                <!-- <b-form-checkbox value="that">Check that out</b-form-checkbox> -->
                                </b-form-checkbox-group>
                            </b-form-group>

                            <b-button type="submit" variant="primary">Bestellen</b-button>
                        <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
                          </b-col>
                        </b-row>
                    </b-form>
                        <!-- <b-card class="mt-3" header="Form Data Result">
                        <pre class="m-0">{{ form }}</pre>
                        </b-card> -->
              </b-col>
              <b-col class="pt-5">
                  <h1 class="pt-5">Gegevens</h1>
              </b-col>
          </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
export default {
    name: 'CustomerData',
    data() {
      return {
        form: {
          email: '',
          name: '',
          food: null,
          checked: []
        },
        foods: [{ text: 'Select One', value: null }, 'Carrots', 'Beans', 'Tomatoes', 'Corn'],
        show: true
      }
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault()
        alert(JSON.stringify(this.form))
      },
      onReset(evt) {
        evt.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
    }
}
</script>

<style scoped>
  .customer-data .bgimage {
    background: url('../assets/header/banner-scooter.jpg');
    /* height: 500px; */
    background-size: 50%;
    /* background-size: cover; */
    background-position: right;
    background-repeat: no-repeat;
  }
</style>